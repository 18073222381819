import { mapGetters } from "vuex";
import { constants } from "../constants/constants";
import { permControl } from "../constants/helpers";

export default {
	data() {
		return {
			permissions: constants.permissions,
		};
	},
	computed: {
		...mapGetters({
			_usrPerm: "userPerm",
		}),
	},
	methods: {
		/**
		 *
		 * @param  {...{key:string,val:number}} flags
		 * @returns {boolean}
		 */
		_usrFlagsSome(...flags) {
			// return userFlagsSome(this._usrPerm, ...flags);
			return permControl(this._usrPerm, ...flags);
		},
		_otherUsrFlagsSome(_usrPerm, ...flags) {
			// return userFlagsSome(this._usrPerm, ...flags);
			return permControl(_usrPerm, ...flags);
		},
		// _usrFlagsAll(...flags) {
		// 	return userFlagsAll(this._usrPerm, ...flags);
		// },
	},
};
