var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t("default", null, { showConfirm: _vm.showConfirm }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: _vm.persistent, "max-width": _vm.maxWidth },
          on: {
            "click:outside": _vm.cancel,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              $event.preventDefault()
              return _vm.cancel.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showMe,
            callback: function ($$v) {
              _vm.showMe = $$v
            },
            expression: "showMe",
          },
        },
        [
          _c(
            "v-card",
            [
              _vm._t("card-title", function () {
                return [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "font-weight-bold py-3",
                      class: _vm.titleClass,
                    },
                    [
                      _vm._t("title-text", function () {
                        return [
                          _c("span", { class: _vm.titleTextClass }, [
                            _vm._v(_vm._s(_vm.titleText)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
              _c("v-card-text", [_vm._t("body")], 2),
              _vm._t(
                "actions",
                function () {
                  return [
                    _c(
                      "v-card-actions",
                      [
                        _vm._t(
                          "cancel-btn",
                          function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: _vm.cancelBtnColor,
                                    text: _vm.cancelIsTextBtn,
                                  },
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v(" " + _vm._s(_vm.cancelBtnText) + " ")]
                              ),
                            ]
                          },
                          { cancel: _vm.cancel }
                        ),
                        _c("v-spacer"),
                        _vm._t(
                          "confirm-btn",
                          function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: _vm.confirmBtnColor,
                                    text: _vm.confirmIsTextBtn,
                                  },
                                  on: { click: _vm.confirm },
                                },
                                [_vm._v(" " + _vm._s(_vm.confirmBtnText) + " ")]
                              ),
                            ]
                          },
                          { confirm: _vm.confirm }
                        ),
                      ],
                      2
                    ),
                  ]
                },
                { cancel: _vm.cancel, confirm: _vm.confirm }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }