<template>
	<span>
		<slot :showConfirm="showConfirm"></slot>
		<v-dialog
			@click:outside="cancel"
			@keydown.esc.prevent="cancel"
			:persistent="persistent"
			v-model="showMe"
			:max-width="maxWidth">
			<v-card>
				<slot name="card-title">
					<v-card-title
						class="font-weight-bold py-3"
						:class="titleClass">
						<slot name="title-text">
							<span :class="titleTextClass">{{ titleText }}</span>
						</slot>
					</v-card-title>
				</slot>

				<v-card-text>
					<slot name="body"></slot>
				</v-card-text>
				<slot
					name="actions"
					:cancel="cancel"
					:confirm="confirm">
					<v-card-actions>
						<slot
							name="cancel-btn"
							:cancel="cancel">
							<v-btn
								:color="cancelBtnColor"
								:text="cancelIsTextBtn"
								@click="cancel">
								{{ cancelBtnText }}
							</v-btn>
						</slot>
						<v-spacer />
						<slot
							name="confirm-btn"
							:confirm="confirm">
							<v-btn
								:color="confirmBtnColor"
								:text="confirmIsTextBtn"
								@click="confirm">
								{{ confirmBtnText }}
							</v-btn>
						</slot>
					</v-card-actions>
				</slot>
			</v-card>
		</v-dialog>
	</span>
</template>
<script>
export default {
	props: {
		shouldShow: {
			type: Boolean,
			default: true,
		},
		maxWidth: {
			type: String,
			default: "500px",
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		titleClass: {
			type: String,
			default: "primary lighten-1",
		},
		titleTextClass: {
			type: String,
			default: "text-h5",
		},
		cancelBtnColor: {
			type: String,
			default: "error",
		},
		confirmBtnColor: {
			type: String,
			default: "primary",
		},
		cancelIsTextBtn: {
			type: Boolean,
			default: true,
		},
		confirmIsTextBtn: {
			type: Boolean,
			default: true,
		},
		titleText: {
			type: String,
			default: "Are you Sure?",
		},
		cancelBtnText: {
			type: String,
			default: "NO",
		},
		confirmBtnText: {
			type: String,
			default: "YES",
		},
	},
	data() {
		return {
			showMe: false,
		};
	},
	methods: {
		showConfirm() {
			if (this.shouldShow) {
				this.showMe = true;
			} else {
				this.confirm();
			}
		},
		cancel() {
			this.$emit("cancel");
			this.showMe = false;
		},
		confirm() {
			this.$emit("confirm");
			this.showMe = false;
		},
	},
};
</script>
